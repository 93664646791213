<template>
  <div class="flex items-center">
    {{ statusName[parseInt(status) - 1] }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data: () => ({
    statusName: ['Init Sales', 'Demo Done', 'Quotation Sent', 'Work on Budget', 'Order Delivery', 'Delay', 'Order Lost'],
  }),
  computed: {
    status() {
      return this.params.data.status;
    },
  }
}
</script>
