<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px; align-items: center;">
          <v-select v-model="year" :clearable="false" :options="yearOptions"></v-select>
          <!-- Button Add -->
          <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>
        </div>

      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>

    </div>

    <!--  Popup  -->
    <vs-popup classContent="popup-example" :title="`${mode.charAt(0).toUpperCase() +  mode.slice(1)} ${mode === 'detail' ? 'Forecast' : 'Item'}`" :active.sync="popup">

      <!-- show form when mode is not detail -->
      <div v-if="mode == 'edit' || mode == 'add'">
        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <label class="vs-input--label">{{$i18n.t('Date')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.date" @input="form.date = $moment($event)"></datepicker>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Customer') }}</small>
            <v-select v-model="form.company_name" :clearable="false" label="nama" :options="customersOptions"></v-select>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Project')" v-model="form.project_name" />
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Merk') }}</small>
            <v-select v-model="form.merk" :clearable="false" label="nama" :options="merksOptions"></v-select>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Product')" v-model="form.product" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input type="number" class="w-full mb-3" :label="$i18n.t('QTY')" v-model="form.qty" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full">
            <small class="mb-1">{{ $i18n.t('Currency') }}</small>
            <v-select v-model="form.currency" :clearable="false" :options="['EUR', 'USD', 'IDR']"></v-select>
          </div>
          <div class="vx-col md:w-3/4 w-full">
            <vs-input type="number" class="w-full mb-3" :label="$i18n.t('TotalPrice')" v-model="form.total_price" />
          </div>

        </div>

        <div class="vx-row" v-if="mode == 'edit'">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Status') }}</small>
            <v-select v-model="form.statusName" :clearable="false" :options="statusName"></v-select>
          </div>
        </div>

        <div class="mt-5">
          <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
            {{ mode == 'add' ? $i18n.t('Save') : $i18n.t('Update') }}
          </vs-button>
        </div>
      </div>

      <!-- show detail -->
      <div class="grid grid-cols-2 justify-center" v-else>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            Customer
          </h1>
          <span class="block">
            {{ itemSelected.company_name }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('ProjectName') }}
          </h1>
          <span class="block">
            {{ itemSelected.project_name }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('Merk') }}
          </h1>
          <span class="block">
            {{ itemSelected.merk }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('Quantity') }}
          </h1>
          <span class="block">
            {{ itemSelected.qty }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('TotalPrice') }}
          </h1>
          <span class="block">
            {{ Number(itemSelected.total_price).toLocaleString().replaceAll(',', '.') }}
          </span>
        </div>
        <div class="container">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('DateCreate') }}
          </h1>
          <span class="block">
            {{ $moment(itemSelected.date_create) }}
          </span>
        </div>
        <div class="container">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('Status') }}
          </h1>
          <span class="block">
            {{ statusName[parseInt(itemSelected.status) - 1] }}
          </span>
        </div>
      </div>

    </vs-popup>

  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from "vuejs-datepicker";
// import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import moment from "moment";


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,
    flatPickr,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      yearOptions: [],
      year: '',
      form: {
        currency: 'IDR',
      },
      statusName: ['Init Sales', 'Demo Done', 'Quotation Sent', 'Work on Budget', 'Order Delivery', 'Delay', 'Order Lost'],
      popup: false,
      mode: 'add',
      itemSelected: false,
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: true,
        defaultHour: 8,
        minuteIncrement: 30
      },
      customersOptions: [],
      merksOptions: [],

      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Customer'),
          field: 'company_name',
          filter: true,
          width: 100,
        },
        {
          headerName: this.$i18n.t('ProjectName'),
          field: 'project_name',
          filter: true,
          cellRendererFramework: 'CellRendererLink',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
          },
        },
        {
          headerName: this.$i18n.t('Merk'),
          field: 'merk',
          filter: true,
          width: 100,
        },
        {
          headerName: this.$i18n.t('Status'),
          field: 'status',
          filter: true,
          width: 80,
          cellRendererFramework: 'CellRendererStatus',
          cellClass: 'text-center',
          headerClass: 'text-center'
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            showItem: this.showItem.bind(this),
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
          },
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    // total_price () {
    //   return this.form.currency + ' ' + this.form.price;
    // }
  },
  methods: {
    init(){
      this.year = new Date().getFullYear();

      for(let i = 2015; i <= parseInt(this.year); i++){
        this.yearOptions.push(i.toString())
      }

      this.yearOptions.reverse()
    },

    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getData () {
      axios.get('/sales/forecasts/' + this.year).then(resp => this.itemsData = resp.data.values ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },
    showItem(item){
      axios.get(`/sales/forecasts/${item.id}/detail`)
      .then(({data}) => {
        this.popup = true
        this.mode = 'detail'
        this.itemSelected = data.values.map(item => {
          return item;
        })[0]
      })
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';

      const currentDate = new Date().toLocaleDateString('id', {
        day: 'numeric',
        month: 'numeric',
      })

      this.form.date = this.$moment(`${currentDate}/${this.year}`)
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        company_name: this.customersOptions.find(item2 => item2.nama == item.company_name),
        project_name: item.project_name,
        merk: this.merksOptions.find(item2 => item2.nama == item.merk),
        product: item.product,
        qty: item.qty,
        currency: item.currency_type,
        total_price: item.total_price,
        date: item.date_create,
        statusName: this.statusName[parseInt(item.status) - 1],
      };
    },
    deleteItem (item) {
      axios.delete('/sales/forecasts/' + item.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          id_user: this.$store.state.AppActiveUser.nik,
          company_name: this.form.company_name.nama,
          project_name: this.form.project_name,
          merk: this.form.merk.nama,
          product: this.form.product,
          qty: this.form.qty,
          currency_type: this.form.currency,
          total_price: this.form.total_price,
          unit_price: this.form.total_price,
          date: this.form.date
        };

        axios.post('/sales/forecasts/', body).then(resp => {
          this.popup = false;

          this.getData();

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          company_name: this.form.company_name.nama,
          project_name: this.form.project_name,
          merk: this.form.merk.nama,
          product: this.form.product,
          qty: this.form.qty,
          currency_type: this.form.currency,
          total_price: this.form.total_price,
          unit_price: this.form.total_price,
          date: this.form.date,
          status: this.statusName.findIndex(status => this.form.statusName == status) + 1,
          _method: 'PUT',
        };

        axios.post('/sales/forecasts/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.getData();

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
            this.mode = false;
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  watch: {
    popup () {
      if (!this.popup) {
        this.form = {
          currency: 'IDR',
        };
      }
    },

    year(year){
      axios.get('/sales/forecasts/' + year).then(resp => this.itemsData = resp.data.values ).catch(err => {
        this.$vs.notify({
          title:'Oops something error',
          text: err.data.message,
          color:'danger'
        })
      })
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  created () {
    // setting default year
    this.init();

    this.loading();

    axios.get('/sales/company').then(resp => this.customersOptions = resp.data ).catch(err => {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    })

    axios.get('/sales/merk').then(resp => this.merksOptions = resp.data ).catch(err => {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    })

    this.getData();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.con-vs-popup .vs-popup--content {
  //overflow: visible;
}
</style>
