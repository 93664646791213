<template>
  <div class="flex items-center">
    <a href="" @click.prevent="url" class="text-primary">{{ params.value }}</a>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    },

    url() {
      return () => {
        this.params.editItem(this.params.data);
      };
    }
  }
}
</script>
